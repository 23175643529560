.wrapper {
    display: grid;
    grid-template-columns: 30px auto auto;
    align-items: center;
    cursor: pointer;
}

.floating-menu {
    position: absolute;
    right: 0;
    z-index: 3;
    margin-top: var(--g-spacing-sm);
    border-radius: var(--s-border-radius-background-small);
    box-shadow: var(--s-shadow-down);
    overflow: hidden;
}

.my-account-menu-item {
    position: relative;

    > button svg {
        transition: transform var(--g-duration-xshort) ease-in-out;
    }

    > button[aria-expanded="true"] svg {
        transform: rotate(180deg);
    }

    > button[aria-expanded="false"] svg {
        transform: rotate(0);
    }
}

.zendesk-link {
    text-decoration: none;
}

@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .list--popover {
        display: grid;
        grid-template-columns: repeat(
            var(--filter-count),
            calc(1 / var(--filter-count) * 100%)
        );
        padding: 0 var(--g-spacing-sm);
        position: relative;

        &::before {
            background: var(--g-color-white);
            border-radius: var(--c-filter-bar-border-radius);
            box-shadow: var(--s-shadow-standard-default);
            content: "";
            height: 100%;
            left: 0;
            outline: var(--g-border-width-xs) solid transparent;
            outline-offset: calc(-1 * var(--g-border-width-xs));
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}

@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .container {
        overflow: hidden;
    }

    .grid {
        display: flex;
        flex-wrap: nowrap;
        margin-top: var(--g-spacing-lg);
        margin-bottom: var(--g-spacing-xl);
        gap: var(--g-spacing-xl);

        @include breakpoint-md {
            overflow: hidden;
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        min-width: 80%;

        @include breakpoint-md {
            min-width: calc(50% - var(--g-spacing-md));
        }

        @include breakpoint-lg {
            min-width: calc(100% / var(--layout) - var(--g-spacing-xl));
        }

        .card-content {
            display: flex;
            flex-direction: column;
            gap: var(--g-spacing-sm);
            margin-top: var(--g-spacing-md);
        }
    }

    .image {
        height: 194px;
        aspect-ratio: 16/9;
        width: 100%;
    }
}

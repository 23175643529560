@use "../../../styles/mixins" as *;
@include layer(ui) {
    .nav {
        display: flex;
        min-height: 60px;
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-bold);
        font-size: min(var(--g-font-size-400), 16px);
        line-height: var(--g-line-height-400);
        color: var(--s-color-text-primary);
        border-bottom: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);
        &--disabled {
            color: var(--s-color-disabled-primary);
        }
    }

    .group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        gap: var(--g-spacing-xs);
        padding-left: var(--g-spacing-sm);
        padding-right: var(--g-spacing-sm);
        width: 60%;
        min-width: 0;
        & + & {
            min-width: 110px;
            width: 40%;
            border-left: var(--g-border-width-xs) solid
                var(--s-color-separator-standard);
        }
        span {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .nav-button {
        width: 30px;
        height: 30px;
        border-radius: var(--c-calendar-button-border-radius);
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--background-default);
        color: var(--icon-color-default);
        padding: 0;
        margin: 0;
        &:hover:not(.nav-button--disabled) {
            cursor: pointer;
            background-color: var(--background-active);
            color: var(--icon-color-active);
        }
        &:active:not(.nav-button--disabled) {
            transform: scale(0.96);
        }
        &:focus-visible {
            &:not(.nav-button--disabled) {
                background-color: var(--background-focus);
                color: var(--icon-color-focus);
            }
            outline: var(--g-border-width-sm) solid var(--outline-color-focus);
        }
        &--disabled {
            cursor: not-allowed;
            background-color: var(--background-disabled);
            color: var(--icon-color-disabled);
            &:focus-visible {
                outline: var(--g-border-width-sm) solid currentColor;
            }
        }
    }

    .icon {
        width: 24px;
        height: 24px;
        stroke: currentColor;
        stroke-width: var(--c-calendar-button-icon-stroke-width);
    }
}

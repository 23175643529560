@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .wrapper {
        scroll-margin-top: var(--g-spacing-md);
        position: relative;
    }

    .filter-bar-wrapper {
        // display: inline allows .sticky-wrapper to stick relative to .wrapper instead of .filter-bar-wrapper
        display: inline;
    }

    .gateway-selector-wrapper {
        position: relative;
        z-index: 2;
    }

    .sticky-wrapper {
        margin-bottom: var(--g-spacing-md);
        position: sticky;
        top: var(--g-spacing-md);
        z-index: 1;
    }

    .results-wrapper {
        position: relative;
        z-index: 0;
    }

    .selected-filters-wrapper {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: var(--g-spacing-sm) var(--g-spacing-md);
    }
}

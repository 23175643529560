@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .timer {
        display: flex;
        flex-direction: column;
        padding: var(--g-spacing-md);
        border-radius: var(--s-border-radius-background-standard);

        @include for-tablet-up {
            padding: var(--g-spacing-xl);
        }

        > * {
            &:not(:last-child) {
                margin-bottom: var(--g-spacing-xxs);

                @include for-tablet-up {
                    margin-bottom: var(--g-spacing-sm);
                }
            }
        }

        &--left {
            justify-content: flex-start;
            text-align: left;
        }

        &--center {
            text-align: center;
            justify-content: center;
        }

        &--background-color {
            background: var(--background-color);
        }

        &--background-gradient {
            background: linear-gradient(
                var(--background-color-gradient-angle),
                var(--background-start-color),
                var(--background-end-color)
            );
        }

        &--flat {
            padding: 0;
            background: none;
        }
    }

    .timer-container {
        display: inline-flex;
        flex-direction: row;
    }
    .timer-unit {
        display: flex;
        flex-direction: row;
        > * {
            margin-right: var(--g-spacing-xs);
        }

        &:not(:first-child) {
            min-width: var(--timer-unit-min-width);
            justify-content: center;
        }
    }

    .separator {
        margin: 0 var(--g-spacing-xs);
    }
}

@use "../../../styles/mixins" as *;
@include layer(ui) {
    .wrapper {
        display: inline-flex;
        gap: var(--g-spacing-xs);
        align-items: center;
        position: relative;
    }

    .stars-group {
        display: inline-flex;
        gap: var(--g-spacing-xxs);
        align-items: center;
        line-height: 0;
        &--bold {
            stroke: var(--s-color-icon-primary);
            stop-color: var(--s-color-icon-primary);
            color: var(--s-color-icon-primary);
        }

        &--standard {
            stroke: var(--c-rating-standard-icon-color);
            color: var(--c-rating-standard-icon-color);
            stop-color: var(--c-rating-standard-icon-color);
        }
    }
}

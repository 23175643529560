@use "../../styles/mixins" as *;
@include layer(ui) {
    .status-label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        flex-shrink: 0;
        gap: var(--g-spacing-xs);
        white-space: nowrap;
        font-family: var(--g-font-family-primary);
        border-radius: var(--c-status-label-border-radius);
        padding: var(--g-spacing-xxs) var(--g-spacing-sm);

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 16px;
                stroke-width: 1.5px;
            }
        }

        &--standard {
            text-transform: uppercase;
            font-weight: var(--g-font-weight-bold);
            font-size: var(--g-font-size-200);
            letter-spacing: var(--g-letter-spacing-loose);
            line-height: var(--g-line-height-200);
            min-height: 22px;
        }

        &--small {
            font-size: 0.8125rem;
            line-height: 100%;
            font-weight: var(--g-font-weight-regular);
            min-height: 20px;
        }
    }

    .primary {
        &--info {
            background-color: var(--s-color-info-background);
            color: var(--s-color-info-foreground);
            border: var(--g-border-width-xs) solid transparent;
        }

        &--alert {
            background-color: var(--s-color-alert-background);
            color: var(--s-color-alert-foreground);
            border: var(--g-border-width-xs) solid transparent;
        }

        &--success {
            background-color: var(--s-color-success-background);
            color: var(--s-color-success-foreground);
            border: var(--g-border-width-xs) solid transparent;
        }

        &--neutral {
            background-color: var(--s-color-neutral-background);
            color: var(--s-color-text-primary);
            border: var(--g-border-width-xs) solid transparent;
        }

        &--urgent {
            background-color: var(--c-status-label-primary-urgent-background);
            color: var(--g-color-ink-black);
            border: var(--g-border-width-xs) solid transparent;
        }

        &--important {
            background-color: var(
                --c-status-label-primary-important-background
            );
            color: var(--g-color-ink-black);
            border: var(--g-border-width-xs) solid transparent;
        }

        &--deal {
            background-color: var(--s-color-promo-deal-background);
            color: var(--s-color-promo-deal-foreground);
            border: var(--g-border-width-xs) solid transparent;
        }
    }

    .secondary {
        &--info {
            background-color: transparent;
            color: var(--s-color-info-standard);
            border: var(--g-border-width-xs) solid var(--s-color-info-border);
        }

        &--alert {
            background-color: transparent;
            color: var(--s-color-alert-standard);
            border: var(--g-border-width-xs) solid var(--s-color-alert-border);
        }

        &--success {
            background-color: transparent;
            color: var(--s-color-success-standard);
            border: var(--g-border-width-xs) solid var(--s-color-success-border);
        }

        &--neutral {
            background-color: transparent;
            color: var(--s-color-neutral-standard);
            border: var(--g-border-width-xs) solid var(--s-color-neutral-border);
        }

        &--urgent {
            background-color: transparent;
            color: var(--c-status-label-secondary-urgent-text-color);
            border: var(--g-border-width-xs) solid
                var(--c-status-label-secondary-urgent-border-color);
        }

        &--important {
            background-color: transparent;
            color: var(--c-status-label-secondary-important-text-color);
            border: var(--g-border-width-xs) solid
                var(--c-status-label-secondary-important-border-color);
        }

        &--deal {
            background-color: transparent;
            color: var(--s-color-promo-deal-standard);
            border: var(--g-border-width-xs) solid
                var(--s-color-promo-deal-border);
        }
    }
}

@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .slide {
        & > * {
            height: 100%;
            width: 100%;
            & > :first-child {
                height: inherit;
                width: 100%;
            }
        }
    }
}

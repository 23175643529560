.wrapper {
    margin: var(--g-spacing-xl) var(--g-spacing-md);
}

.message {
    margin-bottom: var(--g-spacing-sm);
}

.extra-message {
    margin: var(--g-spacing-md) 0;
}

.description {
    text-align: left;
    margin-bottom: var(--g-spacing-md);
}

.more-info {
    margin-left: var(--g-spacing-lg);
    margin-bottom: var(--g-spacing-sm);
}

.header-with-check {
    display: flex;
    align-items: center;
    margin-right: var(--g-spacing-sm);
    margin-bottom: var(--g-spacing-sm);
}

.title-detail {
    margin-left: var(--g-spacing-sm);
}

.title-header {
    margin-bottom: var(--g-spacing-md);
}

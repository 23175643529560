@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .tour-card-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: var(--g-spacing-md);
        justify-content: space-between;
        box-sizing: border-box;
        height: 100%;
    }

    .tour-card-content-badge {
        display: inline-flex;
        margin-bottom: var(--g-spacing-sm);

        @include breakpoint-md {
            left: var(--g-spacing-md);
            position: absolute;
            text-transform: uppercase;
            top: var(--g-spacing-md);
            // HACK: Stop badge from rendering behind the slider in safari
            transform: translate3d(0, 0, 0);
            z-index: 1;
        }
    }

    .tour-card-title {
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-bold);
        line-height: var(--g-line-height-400);
        margin-bottom: var(--g-spacing-xxs);
        max-height: calc(var(--g-line-height-400) * 3 * 1em);
        overflow: hidden;
        text-overflow: ellipsis;

        @supports (-webkit-line-clamp: 3) {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            display: -webkit-box;
        }

        @include breakpoint-md {
            font-size: var(--g-font-size-500);
            line-height: var(--g-line-height-500);
            display: flex;
            overflow: visible;
            text-overflow: clip;
            white-space: normal;
            max-height: initial;
        }
    }

    .tour-card-duration {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: var(--g-spacing-sm);
    }

    .tour-card-rating {
        display: flex;
        gap: 4px;
        margin-bottom: var(--g-spacing-md);
    }

    .tour-card-footer {
        display: flex;
        flex-direction: column;
    }

    .tour-card-price {
        color: var(--s-color-text-primary);
        display: inline-flex;
        flex-wrap: wrap;
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-light);
        gap: var(--g-spacing-xs);
        line-height: var(--g-line-height-400);
        margin-bottom: var(--g-spacing-xxs);

        .strike-through {
            text-decoration: line-through;
        }

        .sale-price {
            color: var(--s-color-promo-price-standard);
            font-weight: var(--g-font-weight-bold);
        }
    }

    .price-badge-wrapper {
        margin-bottom: var(--g-spacing-sm);
        margin-top: var(--g-spacing-sm);
    }

    .tour-card-cta {
        &:focus-visible:not(:disabled),
        &:hover:not(:disabled) {
            color: initial;
        }
    }

    .tour-card-content--grid {
        .tour-card-duration {
            margin-bottom: var(--g-spacing-sm);

            @include breakpoint-md {
                overflow: visible;
                text-overflow: clip;
                white-space: normal;
            }
        }

        .tour-card-footer {
            align-items: flex-start;
            align-self: flex-end;
            flex-direction: column;
            justify-content: flex-end;
            gap: var(--g-spacing-xxs);
            width: 100%;

            @include breakpoint-md {
                align-items: end;
                flex-direction: row;
                gap: var(--g-spacing-md);
                justify-content: space-between;
            }
        }

        .tour-card-price {
            @include breakpoint-md {
                margin-bottom: 0;
            }
        }

        .price-badge-wrapper {
            @include breakpoint-md {
                margin-bottom: 0;
            }
        }
    }

    .tour-card-content--desktop {
        .tour-card-footer {
            align-items: end;
            flex-direction: row;
            gap: var(--g-spacing-md);
            justify-content: space-between;
        }

        .price-badge-wrapper {
            margin-bottom: 0;
        }
    }

    .tour-card-content--slider,
    .tour-card-content--desktop {
        .tour-card-content-badge {
            left: var(--g-spacing-md);
            position: absolute;
            text-transform: uppercase;
            top: var(--g-spacing-md);
            // HACK: Stop badge from rendering behind the slider in safari
            transform: translate3d(0, 0, 0);
            z-index: 1;
        }
    }
}

@use "@wojo/ui/mixins" as *;

.wrapper {
    display: flex;
    gap: var(--g-spacing-sm);
    align-items: center;
}

.text {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    font-family: var(--g-font-family-primary);
    font-size: var(--g-font-size-400);
    font-weight: var(--g-font-weight-regular);
    line-height: var(--g-line-height-400);

    @include breakpoint-sm {
        clip: auto;
        clip-path: none;
        height: auto;
        overflow: visible;
        position: static;
        white-space: normal;
        width: auto;
    }

    @include breakpoint-lg {
        font-size: var(--g-font-size-300);
        line-height: var(--g-line-height-300);
    }

    &--modal {
        @include breakpoint-lg {
            font-size: var(--g-font-size-400);
            line-height: var(--g-line-height-400);
        }
    }
}

.button {
    display: flex;
    margin-right: var(--g-spacing-md);
    align-items: center;
    gap: var(--g-spacing-sm);
    padding: 0;
    color: var(--s-color-text-primary);
    text-align: right;
    text-decoration: none;
    font-family: var(--g-font-family-primary);
    font-size: var(--g-font-size-400);
    font-weight: var(--g-font-weight-regular);
    line-height: var(--g-line-height-400);

    @include breakpoint-sm {
        margin-right: var(--g-spacing-lg);
    }

    @include breakpoint-lg {
        font-size: var(--g-font-size-300);
        line-height: var(--g-line-height-300);
        margin-right: unset;
    }

    &--modal {
        margin-right: var(--g-spacing-md);

        @include breakpoint-sm {
            margin-right: var(--g-spacing-lg);
        }
    }
}

.icon {
    height: 20px;
    width: 20px;

    @include breakpoint-lg {
        display: none;
    }

    &--modal {
        display: inline;
    }
}

.card {
    padding: 0;
    border: 0;
    margin-top: var(--g-spacing-lg);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.tab-title {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-xs);
    align-items: center;
}

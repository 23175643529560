@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .tour-card-image {
        display: block;
        height: 100%;

        img {
            display: block;
            height: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }
    }
    .tour-card-image-link {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

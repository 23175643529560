@use "../../../styles/mixins" as *;
@include layer(ui) {
    .wrapper {
        max-width: 380px;
        // include button outline focus in height
        padding-bottom: var(--g-spacing-xxs);
        label {
            display: block;
        }
    }

    .calendar {
        border-radius: var(--s-border-radius-field-standard);
        background-color: var(--s-color-selection-background-default);
        border: var(--s-border-width-field-default) solid
            var(--s-color-field-border-default);
        &--error {
            border-color: var(--s-color-field-border-error);
        }
        &:hover:not(.calendar--disabled) {
            border: var(--s-border-width-field-active) solid
                var(--s-color-field-border-active);
        }
        &:not(.calendar--disabled):not(.calendar--selected):focus-within {
            border: var(--s-border-width-field-focus) solid
                var(--s-color-field-border-focus);
            outline: var(--s-border-width-field-focus) solid
                var(--s-color-field-border-focus);
        }
        &--disabled {
            border: var(--s-border-width-field-default) solid
                var(--s-color-field-border-default);
        }
    }

    .field {
        font-size: var(--g-font-size-400);
        line-height: var(--g-line-height-400);
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: var(--s-size-height-field-standard);
        padding-left: var(--g-spacing-md);
        padding-right: var(--g-spacing-md);
        gap: var(--g-spacing-md);
        background-color: transparent;
        border-bottom: inherit;
        .calendar:not(.calendar--disabled):focus-within & {
            outline: inherit;
            border-top-right-radius: var(--s-border-radius-field-standard);
            border-top-left-radius: var(--s-border-radius-field-standard);
        }
    }

    .date {
        flex-grow: 1;
        color: var(--s-color-text-quiet);
        border: 0;
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        font-size: var(--g-font-size-400);
        line-height: var(--g-line-height-400);
        min-width: 0;
        text-overflow: ellipsis;
        background-color: transparent;
        cursor: default;
        &:focus {
            outline: 0;
        }
        .calendar--selected & {
            color: var(--s-color-text-primary);
        }
        .calendar--disabled & {
            color: var(--s-color-disabled-primary);
        }
    }

    .icon-wrapper {
        display: flex;
        align-items: center;
        color: var(--s-color-icon-primary);
        .calendar--error & {
            color: var(--s-color-alert-standard);
        }
        .calendar--disabled & {
            color: var(--s-color-disabled-primary);
        }
    }

    .icon {
        width: var(--g-size-xxs);
        color: currentColor;
    }

    .footer {
        margin-top: var(--g-spacing-md);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
}

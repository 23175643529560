@use "@wojo/ui/mixins" as *;

.utility-link {
    text-decoration: none;
    color: var(--s-color-text-primary);
    margin-right: var(--g-spacing-md);
    border: none;
    background: none;
    cursor: pointer;

    @include breakpoint-sm {
        margin-right: var(--g-spacing-lg);
    }

    &:visited {
        color: inherit;
    }

    &:focus-visible {
        color: var(--s-color-brand-primary-standard);
        text-decoration: underline;
        outline-color: currentColor;
        outline-width: var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        border-radius: var(--s-border-radius-interactive-focus);
    }

    &:hover,
    &:active {
        color: var(--s-color-brand-primary-standard);
        text-decoration: underline;
        transition: ease var(--g-duration-xshort);
    }

    @include breakpoint-lg {
        margin-right: 0;
        text-align: right;
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-300);
        font-weight: var(--g-font-weight-regular);
        line-height: var(--g-line-height-300);
        text-decoration: none;
        display: block;
    }

    &--modal {
        margin-right: var(--g-spacing-md);

        @include breakpoint-sm {
            margin-right: var(--g-spacing-lg);
        }
    }
}

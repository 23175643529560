@use "../../styles/mixins" as *;
@include layer(ui) {
    .container {
        display: inline-flex;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: center;
        aspect-ratio: 1/1;
        vertical-align: bottom;
        background-color: var(--s-color-surface-quiet);
        padding: var(--g-spacing-xxs);
    }

    .text {
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        color: var(--s-color-text-primary);
        opacity: var(--g-opacity-bold);
        text-transform: uppercase;
    }

    .font {
        &--xs,
        &--sm {
            font-size: var(--g-font-size-100);
            line-height: var(--g-line-height-100);
        }

        &--md {
            font-size: var(--g-font-size-300);
            line-height: var(--g-line-height-300);
        }

        &--lg,
        &--xl {
            font-size: var(--g-font-size-400);
            line-height: var(--g-line-height-400);
        }
    }
}

@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .list--collapsible {
        padding: 0 var(--g-spacing-md);

        @include for-tablet-up {
            padding: 0 var(--g-spacing-xl);
        }

        .content-wrapper {
            padding-top: var(--g-spacing-md);

            @include for-tablet-up {
                padding-top: var(--g-spacing-lg);
            }
        }
    }
    .calendar-filter {
        max-width: 100%;
        width: 380px;
        padding: 0 var(--g-spacing-xxs);
    }
}

@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--g-spacing-xs);
        margin-bottom: var(--g-spacing-md);
        width: 100%;

        @include breakpoint-lg {
            align-items: center;
            flex-direction: row;
            justify-content: flex-end;
            gap: var(--g-spacing-md);
            margin-bottom: var(--g-spacing-lg);
        }
    }

    .combobox-wrapper {
        @include breakpoint-lg {
            width: 310px;
        }
    }
}

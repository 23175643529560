.option-heading-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: var(--g-spacing-xs);
}

.title,
.more-info {
    margin-bottom: 0;
    text-align: center;
    margin-bottom: var(--g-spacing-md);
}

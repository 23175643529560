@use "@wojo/ui/mixins" as *;

.menu-button {
    text-decoration: none;

    &:active {
        color: var(--s-color-brand-primary-standard);
    }

    &:hover,
    &:focus-visible {
        text-decoration: underline;
    }

    @include breakpoint-lg {
        display: none;
    }

    &--modal {
        @include breakpoint-lg {
            display: inline-flex;
        }
    }
}

.modal {
    padding: 0;
}

.modal-header {
    display: flex;
    padding: var(--g-spacing-lg) var(--g-spacing-lg) var(--g-spacing-md);
    background-color: var(--s-color-surface-secondary);

    @include breakpoint-md {
        padding-left: var(--g-spacing-xl);
        padding-right: var(--g-spacing-xl);
    }
}

.modal-header-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modal-nav {
    display: flex;
    align-items: center;
}

.modal-logo-link {
    &:focus-visible {
        color: var(--s-color-brand-primary-standard);
        outline-color: currentColor;
        outline-width: var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        border-radius: var(--c-header-link-outline-border-radius-focus);
    }
}

.modal-logo {
    display: flex;
    img {
        height: 20px;
        width: auto;
    }
}

.modal-body {
    padding: 0;
}

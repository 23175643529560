@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .list {
        display: contents;
        margin: 0;
        padding: 0;
    }

    .item {
        display: flex;
        margin: 0;
    }
}

@use "../../styles/mixins" as *;
@mixin pagination-button {
    font-family: var(--g-font-family-primary);
    font-size: var(--g-font-size-400);
    line-height: var(--g-line-height-400);
    font-weight: var(--g-font-weight-regular);
    border: none;
    padding: 0;
    box-sizing: border-box;
    background-color: transparent;
    border-radius: var(--c-pagination-button-border-radius);
    color: var(--s-color-selection-foreground-default);
    cursor: pointer;
    display: flex;
    min-height: 40px;
    min-width: 40px;
    place-content: center;
    place-items: center;

    svg {
        width: 24px;
        height: 24px;
        color: currentColor;
        stroke-width: 1.25px;
    }

    &:focus-visible:not([aria-disabled="true"]) {
        background-color: var(--s-color-selection-background-active);
        color: var(--c-pagination-button-icon-color-focus);
        outline-offset: var(--g-spacing-xxs);
        outline: var(--g-border-width-sm) solid
            var(--c-pagination-button-outline-color-focus);
        filter: none;
        box-shadow: none;
    }

    &:hover:not([aria-disabled="true"]) {
        transition-duration: var(--g-duration-xshort);
        transition-property: background-color, color, filter;
    }

    &[aria-disabled="true"] {
        background-color: transparent;
        color: var(--s-color-disabled-primary);
        filter: none;
        cursor: default;

        &:focus-visible {
            outline: none;
        }
    }

    @media (forced-colors: active) {
        border: var(--g-border-width-sm) solid transparent;
    }
}

@include layer(ui) {
    .pagination-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-family: var(--g-font-family-primary);
        justify-content: center;
        position: relative;
    }

    .pagination {
        display: flex;
        align-items: center;

        &-label {
            font-size: var(--g-font-size-400);
            font-weight: var(--g-font-weight-light);
            line-height: var(--g-line-height-400);
            color: var(--s-color-text-primary);
            margin-bottom: var(--g-spacing-md);
        }

        &-items {
            list-style: none;
            display: flex;
            padding: 0;
            margin: 0;

            li {
                display: flex;
            }
        }
    }

    .pagination-items {
        padding-left: 0;
        gap: var(--c-pagination-button-spacing-horizontal);

        & > li {
            padding-left: 0;
            margin-top: 0;
            left: 0;

            &::before {
                content: "''";
                display: none;
                left: 0;
            }
        }
    }

    .pagination-item {
        @include pagination-button();
        background-color: var(--s-color-selection-background-default);
        border-radius: var(--c-pagination-number-border-radius);
        box-shadow: (var(--s-shadow-standard-default));
        // fix safari filter shadow bug
        user-select: none;
        overflow: hidden;

        &:hover,
        &:active {
            background-color: var(--s-color-selection-background-active);
            color: var(--s-color-selection-foreground-active);
            box-shadow: none;
            filter: none;
        }

        &.active {
            background-color: var(--s-color-selection-background-selected-bold);
            filter: none;
            color: var(--s-color-selection-foreground-selected-bold);
            font-weight: var(--g-font-weight-bold);
            box-shadow: none;

            &:focus-visible,
            &:hover {
                background-color: var(
                    --s-color-selection-background-selected-bold
                );
                color: var(--s-color-selection-foreground-selected-bold);
                filter: none;
            }
        }
    }

    .next-button,
    .previous-button {
        @include pagination-button();
        color: var(--c-pagination-button-icon-color-default);
        margin-left: var(--c-pagination-button-spacing-horizontal);
        margin-right: var(--c-pagination-button-spacing-horizontal);

        &:not([aria-disabled="true"]) {
            &:hover,
            &:active {
                background-color: var(--c-pagination-button-background-active);
                color: var(--c-pagination-button-icon-color-active);
            }
        }
    }

    .truncation-wrapper {
        display: flex;
        place-items: center;
        place-content: center;
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-regular);
        color: var(--s-color-text-primary);
        user-select: none;
    }
}

@use "@wojo/ui/mixins" as *;

.menu-list {
    padding: 0;
    list-style: none;
}

.menu-item {
    margin: 0;
    margin-bottom: var(--g-spacing-md);
}

.title-with-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--g-spacing-xs);
}

.collapsible {
    padding: var(--g-spacing-md) var(--g-spacing-lg);

    &--expanded {
        background-color: var(--s-color-surface-primary);
    }

    @include breakpoint-md {
        padding-left: var(--g-spacing-xl);
        padding-right: var(--g-spacing-xl);
    }
}

.zendesk-link {
    text-decoration: none;
}

@use "@wojo/ui/mixins" as *;

.wrapper {
    display: flex;
    flex-direction: column;
    border-radius: var(--s-border-radius-background-standard);
    background-color: var(--s-color-brand-secondary-quiet);
    column-gap: var(--g-spacing-xxs);
    padding: var(--g-spacing-lg);

    @include for-tablet-up {
        flex-direction: row;
        justify-content: space-between;
    }
}

.text-section {
    padding-bottom: var(--g-spacing-md);

    @include for-tablet-up {
        padding-bottom: 0;
        padding-right: var(--g-spacing-md);
    }
}

.banner-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--g-spacing-sm);

    @include for-tablet-up {
        gap: var(--g-spacing-md);
    }
}

.badge-link {
    display: flex;
    img {
        min-height: 38px;
    }

    &:focus-visible {
        color: var(--s-color-brand-primary-standard);
        outline-color: currentColor;
        outline-width: var(--g-border-width-sm);
        outline-offset: var(--g-spacing-xxs);
        outline-style: solid;
        border-radius: var(--c-header-link-outline-border-radius-focus);
    }
}

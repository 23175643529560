@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .grid {
        margin-top: var(--g-spacing-lg);
        margin-bottom: var(--g-spacing-lg);
        @include breakpoint-md {
            margin-bottom: var(--g-spacing-xl);
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        width: 100%;

        .card-content {
            display: flex;
            flex-direction: column;
            gap: var(--g-spacing-sm);
            margin-top: var(--g-spacing-md);
        }
    }

    .image {
        height: 194px;
        @include breakpoint-md {
            aspect-ratio: 3/2;
            height: unset;
            width: 100%;
        }
    }
}

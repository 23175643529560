@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .wrapper {
        border-right: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);
        display: flex;
        flex: 1;
        position: relative;

        &:last-of-type {
            border: 0;
        }
    }

    .trigger {
        align-items: center;
        background: none;
        border: 0;
        color: var(--s-color-text-primary);
        cursor: pointer;
        display: flex;
        flex: 1;
        height: 48px;
        text-align: left;

        &:active:not(:disabled),
        &:hover:not(:disabled) {
            .trigger-content {
                color: var(--s-color-brand-primary-standard);
                text-decoration: underline;
            }
        }

        &:focus-visible {
            outline: none;

            .trigger-content {
                border-radius: var(--c-filter-button-border-radius-focus);
                color: var(--s-color-brand-primary-standard);
                outline: var(--g-border-width-sm) solid
                    var(--s-color-brand-primary-standard);
                text-decoration: underline;
            }
        }
    }

    .trigger-content {
        align-items: center;
        display: grid;
        flex: 1;
        grid-template-columns: 1fr 16px;
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-regular);
        justify-content: space-between;
        line-height: var(--g-line-height-400);
        padding: var(--g-spacing-xs) var(--g-spacing-sm);
    }

    .trigger-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .trigger-icon {
        &--expanded {
            transform: rotate(180deg);
        }
    }

    .content {
        display: none;

        &--expanded {
            display: block;
        }
    }

    .children-wrapper {
        max-height: 460px;
        overflow: auto;
        white-space: nowrap;
    }
}

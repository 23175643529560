@use "../../../styles/mixins" as *;
@include layer(ui) {
    .calendar {
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        font-size: min(var(--g-font-size-300), 25px);
        line-height: var(--g-line-height-300);
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
    }

    .weekday {
        font-size: min(var(--g-font-size-300), 21px);
        font-weight: var(--g-font-weight-regular);
        padding-top: var(--g-spacing-sm);
        padding-bottom: var(--g-spacing-sm);
        color: var(--s-color-text-secondary);
        .calendar--disabled & {
            color: var(--s-color-disabled-primary);
        }
    }

    .date-cell {
        text-align: center;
        padding: 0;
    }

    .date {
        cursor: pointer;
        font-family: var(--g-font-family-primary);
        font-size: min(var(--g-font-size-300), 25px);
        min-height: 40px;
        border-radius: var(--s-border-radius-field-standard);
        background-color: transparent;
        border: 0;
        width: 100%;
        color: var(--s-color-selection-foreground-default);
        &:hover:not(.date--disabled):not(.date--selected) {
            background-color: var(--s-color-selection-background-active);
            color: var(--s-color-selection-foreground-active);
        }
        &:focus-visible {
            &:not(.date--disabled):not(.date--selected) {
                background-color: var(--s-color-selection-background-active);
                color: var(--s-color-selection-foreground-active);
            }
            outline: var(--g-border-width-sm) solid
                var(--s-color-selection-border-focus);
        }
        &--today {
            font-weight: var(--g-font-weight-bold);
        }
        &--selected {
            background-color: var(--s-color-selection-background-selected-bold);
            color: var(--s-color-selection-foreground-selected-bold);
            font-weight: var(--g-font-weight-bold);
            outline: 1px solid transparent;
        }
        &--disabled,
        &[aria-disabled] {
            cursor: not-allowed;
            background-color: transparent;
            color: var(--s-color-disabled-primary);
            &:focus-visible {
                outline: var(--g-border-width-sm) solid currentColor;
            }
        }
    }

    .range {
        &--middle {
            background-color: var(--s-color-selection-background-active);
            color: var(--s-color-selection-foreground-active);
            border-radius: 0;
        }
        &--end {
            background-color: var(--s-color-selection-background-selected-bold);
            color: var(--s-color-selection-foreground-selected-bold);
            border-radius: 0 var(--s-border-radius-field-standard)
                var(--s-border-radius-field-standard) 0;
        }
        &--start {
            background-color: var(--s-color-selection-background-selected-bold);
            color: var(--s-color-selection-foreground-selected-bold);
            border-radius: var(--s-border-radius-field-standard) 0 0
                var(--s-border-radius-field-standard);
        }
    }

    .footer {
        padding: var(--g-spacing-md);
    }

    .reset {
    }
}

.wrapper {
    padding: var(--g-size-xxs) 0;
    border-top: var(--g-border-width-xs) solid var(--s-color-separator-standard);
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--g-border-radius-md);
}

.aside {
    justify-content: flex-end;
    display: flex;
    text-align: right;
    align-items: center;
}

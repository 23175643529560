@use "@wojo/ui/mixins" as *;

.support-overview-heading {
    padding-bottom: var(--g-spacing-md);
}
.support-overview-wrapper {
    padding: var(--g-spacing-md);
    @include breakpoint-sm {
        padding: var(--g-spacing-lg) var(--g-spacing-xl);
    }
}
.support-overview-list {
    padding: 0;
    margin: 0;
    list-style: none;
    > :first-child {
        padding-top: 0;
    }
    > :last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
}

.zendesk-link {
    font-weight: var(--g-font-weight-bold);
    justify-content: space-between;
    text-decoration: none;
    width: 100%;
}

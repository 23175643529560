@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .tour-card {
        display: flex;
        position: relative;
        transition: filter var(--g-duration-xshort) ease;

        padding: 0;
        border: 0;

        &:hover,
        &:focus-within {
            box-shadow: (var(--s-shadow-standard-active));
        }

        @media (forced-colors: active) {
            border: var(--g-border-width-sm) solid transparent;
        }
    }

    .tour-card-content-link {
        text-decoration: none;

        &:focus-visible {
            outline: none;

            .tour-card-focus-ring {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 0;
                outline: var(--g-border-width-sm) solid
                    var(--s-color-brand-primary-standard);
                outline-offset: unset;
                transition: none;
                border-radius: var(--s-border-radius-background-standard);
                pointer-events: none;
            }
        }
    }

    .tour-card-media-wrapper {
        @include breakpoint-md {
            grid-template-columns: none;
            grid-template-rows: max-content auto;
        }
    }

    .tour-card--grid {
        display: grid;
        width: 100%;
        min-height: 194px;
        grid-template-columns: var(--c-tour-card-grid-image-width) calc(
                100% - var(--c-tour-card-grid-image-width)
            );

        @include breakpoint-md {
            grid-template-columns: none;
            grid-template-rows: max-content auto;
        }

        .tour-card-wishlist-button-wrapper {
            position: absolute;
            left: var(--g-spacing-sm);
            top: var(--g-spacing-sm);
            // HACK: Stop button from rendering behind the slider in safari
            transform: translate3d(0, 0, 0);
            z-index: 2;

            @include breakpoint-md {
                left: auto;
                right: var(--g-spacing-sm);
            }
        }

        .tour-card-content-wrapper {
            order: 1;
        }

        .tour-card-content-link {
            flex-direction: row;
            order: 1;
        }

        .tour-card-media-wrapper {
            position: relative;
            flex-shrink: 0;
            order: 0;
            overflow: hidden;
            border-top-left-radius: var(--s-border-radius-background-standard);
            border-bottom-left-radius: var(
                --s-border-radius-background-standard
            );

            @include breakpoint-md {
                border-top-right-radius: var(
                    --s-border-radius-background-standard
                );
                border-bottom-left-radius: 0;
                width: 100%;
                height: auto;
                aspect-ratio: 3/2;
            }
        }
    }

    .tour-card--slider,
    .tour-card--desktop {
        display: flex;
        flex-direction: column;
        width: 280px;

        .tour-card-content-wrapper {
            height: 100%;
            order: 1;
        }

        .tour-card-content-link {
            height: 100%;
        }

        .tour-card-wishlist-button-wrapper {
            position: absolute;
            right: var(--g-spacing-sm);
            top: var(--g-spacing-sm);
            // HACK: Stop button from rendering behind the slider in safari
            transform: translate3d(0, 0, 0);
            z-index: 2;
        }

        .tour-card-media-wrapper {
            flex-shrink: 0;
            order: 0;
            overflow: hidden;
            border-top-left-radius: var(--s-border-radius-background-standard);
            border-top-right-radius: var(--s-border-radius-background-standard);
            width: 100%;
            height: auto;
            aspect-ratio: 3/2;
        }
    }

    .tour-card--desktop {
        width: 100%;
    }
}

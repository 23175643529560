@use "@wojo/ui/mixins" as *;

.utility-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: var(--g-spacing-sm);
}

.utility-nav-list {
    margin: 0;
    padding: 0;
    align-items: center;

    @include breakpoint-lg {
        display: flex;
    }
}

.utility-list-item {
    list-style-type: none;
    padding: var(--g-spacing-md) var(--g-spacing-lg);
    border-top: solid;
    border-color: var(--s-color-separator-standard) transparent;
    border-width: var(--g-border-width-xs);

    @include breakpoint-md {
        padding-left: var(--g-spacing-xl);
        padding-right: var(--g-spacing-xl);
    }

    @include breakpoint-lg {
        list-style: none;
        border: unset;
        text-align: center;
        margin: 0;
        width: auto;
        padding: 0 0 0 var(--g-spacing-lg);
        &:first-child {
            padding: 0;
        }
    }
}

.login {
    text-decoration: none;

    &:focus-visible {
        text-decoration: underline;
        text-underline-offset: auto;
    }

    &:hover,
    &:active {
        text-decoration: underline;
        text-underline-offset: auto;
    }
}

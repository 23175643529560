@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .blog-card {
        padding: 0;
        min-width: 280px;

        @include breakpoint-sm {
            min-width: 0;
        }

        &--flat {
            background-color: transparent;
            filter: none;
        }
    }

    .blog-card-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        text-decoration: none;

        &:focus-visible {
            outline: var(--g-border-width-sm) solid
                var(--s-color-brand-primary-standard);
            outline-offset: 0;
            border-radius: var(--s-border-radius-background-standard);
        }
    }

    .blog-card-media {
        overflow: hidden;
        position: relative;
        width: 100%;
        background-color: var(--s-color-surface-quiet);
        aspect-ratio: 16/9;
        border-top-left-radius: var(--s-border-radius-background-standard);
        border-top-right-radius: var(--s-border-radius-background-standard);

        picture {
            display: flex;
        }

        img {
            aspect-ratio: 16/9;
            height: auto;
            width: 100%;
        }
    }

    .blog-card-body {
        padding: var(--g-spacing-md);

        &--flat {
            padding: var(--g-spacing-md) 0 0 0;
        }
    }

    .blog-card-title {
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}

@use "@wojo/ui/mixins" as *;

.mobile {
    @include breakpoint-lg {
        display: none;
    }
}

.menu-container {
    display: flex;
    align-items: center;
}

.loading {
    height: 20px;
    width: 20px;
    margin-right: var(--g-spacing-md);

    @include breakpoint-sm {
        height: 24px;
        width: 140px;
        margin-right: var(--g-spacing-lg);
    }
}

@use "../../styles/mixins" as *;
@include layer(ui) {
    .wrapper {
        display: block;
        padding: calc(var(--c-card-padding) - var(--g-border-width-sm));
        background-color: var(--s-color-surface-secondary);
        // safari drop shadow flicker fix
        box-shadow: (var(--s-shadow-standard-default));
        outline: var(--g-border-width-sm) solid transparent;

        @include for-tablet-up {
            padding: calc(
                var(--c-card-padding-desktop) - var(--g-border-width-sm)
            );
        }

        &--standard {
            border-radius: var(--s-border-radius-background-standard);
        }

        &--small {
            border-radius: var(--s-border-radius-background-small);
        }

        &:where(button, a).wrapper--button {
            outline: var(--g-border-width-sm) solid transparent;
            // TODO Come back to address the issue with borders
            border-width: 0;
            padding: calc(
                var(--c-card-button-padding) - var(--g-border-width-sm)
            );
            text-decoration: none;
            cursor: pointer;

            transition-duration: var(--g-duration-xshort);
            transition-property: filter;
            transition-timing-function: ease;

            @media (prefers-reduced-motion: reduce), (forced-colors: active) {
                transition: none;
            }

            @include for-tablet-up {
                padding: calc(
                    var(--c-card-button-padding-desktop) -
                        var(--g-border-width-sm)
                );
            }

            &:active:not(:disabled),
            &:hover:not(:disabled) {
                box-shadow: (var(--s-shadow-standard-active));
            }

            &:focus-visible {
                outline: var(--c-card-button-outline-width-focus) solid
                    var(--s-color-brand-primary-standard);
                transition: none;
                box-shadow: (var(--s-shadow-standard-active));
            }

            &--standard {
                border-radius: var(--s-border-radius-background-standard);
            }

            &--small {
                border-radius: var(--s-border-radius-background-small);
            }
        }
    }
}

@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .viewport {
        height: 100%;
        position: relative;
        width: 100%;
    }

    .track {
        display: grid;
        grid-template-columns: repeat(var(--nb-items), 100%);
        padding: 0px;
        height: 100%;
        width: 100%;
        transition: translate3d var(--g-duration-xshort) ease;
    }

    .slide {
        height: 100%;
        width: 100%;
        flex-shrink: 0;

        picture {
            display: block;
            height: 100%;
        }

        img {
            display: block;
            height: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }
    }
    .slide-link {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

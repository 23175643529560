@use "../../styles/mixins" as *;
@include layer(ui) {
    .menu {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        min-width: 212px;
        width: fit-content;
        background-color: var(--s-color-surface-secondary);
        border-radius: var(--s-border-radius-background-small);
        box-shadow: (var(--s-shadow-standard-default));
        border: var(--g-border-width-xs) solid transparent;
        transition-duration: var(--g-duration-xshort);
        transition-timing-function: ease;
        transition-property: background-color, border-radius, filter, border,
            transform;

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }

    .menu-items {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
        line-height: inherit;

        & > .menu-item-wrapper {
            margin-left: var(--g-spacing-md);
            margin-right: var(--g-spacing-md);
            padding: var(--g-spacing-md);

            &:not(:first-child):not(.menu-footer-item) {
                border-top: var(--g-border-width-xs) solid
                    var(--s-color-separator-standard);
            }
        }

        & > .menu-footer-item {
            margin-left: 0;
            margin-right: 0;
            padding-left: var(--g-spacing-xl);
            padding-right: var(--g-spacing-xl);
            padding-bottom: var(--g-spacing-sm);
            border-top: none;

            &-first {
                border-top: var(--g-border-width-xs) solid
                    var(--s-color-separator-standard);
            }
        }

        .menu-footer-item:last-child {
            padding-bottom: var(--g-spacing-md);
        }

        &--hide-borders {
            & > .menu-item-wrapper {
                &:not(:first-child):not(.menu-footer-item) {
                    padding-bottom: var(--g-spacing-sm);
                    margin-bottom: var(--g-spacing-sm);
                    padding-top: var(--g-spacing-sm);
                    border: none;
                }

                &:first-child {
                    padding-top: var(--g-spacing-md);
                }
            }

            & > .menu-footer-item {
                &-first {
                    border-top: var(--g-border-width-xs) solid
                        var(--s-color-separator-standard);
                }
            }

            .menu-footer-item:last-child {
                margin-bottom: 0;
            }
        }
    }

    .menu-item {
        all: unset;
        display: flex;
        gap: var(--g-spacing-sm);
        align-items: center;
        text-decoration: none;
        cursor: pointer;

        width: 100%;
        white-space: nowrap;
        box-sizing: border-box;
        color: var(--s-color-text-primary);
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-regular);
        font-size: var(--g-font-size-400);
        line-height: var(--g-line-height-400);

        .menu-item-text {
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .menu-item-text--left {
            display: block;
        }

        .menu-item-text-right {
            flex-grow: 1;
            display: block;
        }

        .menu-item-badge {
            flex-shrink: 0;
            display: flex;

            span {
                display: flex;
            }
            &--right {
                place-items: flex-end;
                place-content: flex-end;
            }

            &--left {
                place-items: flex-start;
                place-content: flex-start;
            }
        }

        &-icon {
            display: flex;
            place-items: center;
            place-content: center;

            svg {
                width: var(--g-size-xxs);
                height: var(--g-size-xxs);
                transition-duration: var(--g-duration-xshort);
                transition-timing-function: ease;
                transition-property: width, height, transform;

                @media (prefers-reduced-motion: reduce) {
                    transition: none;
                }
            }

            flex-shrink: 0;
        }

        &:hover,
        &:active {
            outline: none;
            color: var(--s-color-brand-primary-standard);

            .menu-item-text {
                text-decoration: underline;
            }

            .menu-item-icon {
                svg {
                    color: currentColor;
                }
            }
        }

        &:focus-visible {
            border-radius: var(--c-menu-item-border-radius-focus);
            outline-offset: var(--g-spacing-sm);
            outline-width: var(--g-border-width-sm);
            outline-style: solid;
            color: var(--s-color-brand-primary-standard);

            .menu-item-text {
                text-decoration: underline;
            }

            .menu-item-icon {
                svg {
                    color: currentColor;
                }
            }
        }
    }
}

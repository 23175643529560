@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .container {
        [tabindex="-1"]:focus-visible {
            outline: var(--g-border-width-sm) solid
                var(--s-color-brand-primary-standard);
            outline-offset: var(--g-spacing-xs);
        }
    }

    .wrapper {
        position: relative;
        scroll-margin-top: var(--g-spacing-md);
    }

    .skip {
        &--visually-hidden:not(:focus-visible) {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }

        margin: var(--g-spacing-md) 0;
        scroll-margin-top: var(--g-spacing-md);
    }

    .grid-wrapper {
        margin-bottom: var(--g-spacing-lg);

        @include breakpoint-md {
            margin-bottom: var(--g-spacing-xl);
        }
    }
}

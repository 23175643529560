@use "../../../styles/mixins" as *;
@include layer(ui) {
    .fill-color {
        stroke: currentColor;
        stop-color: currentColor;
        color: currentColor;
    }

    .empty-color {
        stop-color: transparent;
        color: transparent;
    }
}
